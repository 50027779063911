
import { ICompanyService, IClinic, ICompany } from '@/modules/system/types';
import $app from '@/plugins/modules';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Admin } from '@/modules/admin'

@Component
export default class CompanyDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => false })
  readonly processing: boolean | undefined;

  @Prop({ default: () => null })
  readonly company: ICompany | undefined;

  admin = $app.module(Admin);

  showClinic = false;
  selectedClinic: IClinic | null = null;
  processingClinic = false;

  showService = false;
  selectedService: ICompanyService | null = null;
  processingService = false;

  tab = 0;
  item: ICompany | null = null;
  reseting = false;
  pwd = '';

  @Watch('value')
  onShow() {
    if (this.value) {
      this.item = $app.clone(this.company);
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  store() {
    this.$emit('store', this.item)
  }

  remove() {
    this.$emit('remove', this.item)
  }

  async storeClinic(clinic: IClinic) {
    this.processingClinic = true;
    try {
      await this.admin.$store.storeCompanyClinic(clinic);
      const company: ICompany = this.item!;
      company.clinics = company.clinics.filter(i => i.id !== clinic.id)
      company.clinics.push(clinic);
      this.showClinic = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processingClinic = false;
  }

  async removeClinic(clinic: IClinic) {
    this.processingClinic = true;
    try {
      await this.admin.$store.delCompanyClinic(clinic);
      const company: ICompany = this.item!;
      company.clinics = company.clinics.filter(i => i.id !== clinic.id)
      this.showClinic = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processingClinic = false;
  }

  openClinic(clinic: IClinic) {
    this.selectedClinic = clinic;
    this.showClinic = true;
  }

  addClinic() {
    const clinic: IClinic = {
      companyId: this.item?.id!,
      address: '',
      background: '',
      userId: null,
      allowed: true,
      id: null,
      code: '',
      name: '',
      descr: '',
      toolbarDamage: null,
      processing: false
    }
    this.openClinic(clinic);
  }

  async storeService(service: ICompanyService) {
    this.processingService = true;
    try {
      await this.admin.$store.storeCompanyService(service);
      const company: ICompany = this.item!;
      company.services = company.services.filter(i => i.id !== service.id)
      company.services.push(service);
      this.showService = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processingService = false;
  }

  async removeService(service: ICompanyService) {
    this.processingService = true;
    try {
      await this.admin.$store.delCompanyService(service);
      const company: ICompany = this.item!;
      company.services = company.services.filter(i => i.id !== service.id)
      this.showService = false;
    } catch (err) {
      $app.pushError(err);
    }
    this.processingService = false;
  }

  openService(service: ICompanyService) {
    this.selectedService = service;
    this.showService = true;
  }

  addService() {
    const service: ICompanyService = {
      companyId: this.item?.id!,
      id: null,
      validFrom: new Date(),
      validTo: new Date(3000, 1, 1),
      state: 'a'
    }
    this.openService(service);
  }
}
